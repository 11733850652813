// @ts-nocheck
import { observable, action, makeObservable } from 'mobx';

import { getText } from '../../../../i18n';

import { getFullServiceName } from '../../utils/booking';

const LABELS = {
  EMPTYCART: getText('booking:emptyCart'),
  NODATA: getText('emptyCart:noData'),
};

const createNewState = {
  progress: 0,
  bookingId: null,
  services: null,
  errors: [],
  status: 'InProgress',
  statuses: [],
  startDate: '',
  cart: {},
  cartItemByAnalogRooms: {},
  errorMessages: [],
};

const preparedAnalogRooms = list => list
  .sort(({ Price: first }, { Price: second }) => first - second)
  .map((listItem, index) => ({
    ...listItem,
    checked: index === 0,
  }));

export class Store {
  constructor() {
    makeObservable(this);
  }

  @observable bookingInfo = createNewState;

  @action
  updateCart = (cart) => {
    this.bookingInfo.cart = cart;
  };

  @action
  loadCart = (cartItems) => {
    if (!cartItems.Items.length) {
      this.bookingInfo = {
        ...this.bookingInfo,
        errors: [
          ...this.bookingInfo.errors,
          { Message: LABELS.EMPTYCART },
        ],
      };
    } else {
      const services = cartItems.Items.map(item => ({ value: getFullServiceName(item) }));

      this.bookingInfo = {
        ...this.bookingInfo,
        ...createNewState,
        services,
        errors: [],
        totalService: services.length,
        cartItems: cartItems.Items,
        cart: cartItems,
      };
    }
  };

  @action
  updateBookingStatus = (cart) => {
    this.bookingInfo = {
      ...this.bookingInfo,
      status: cart.CartBookingStatus,
      statuses: cart.Items,
      trip: cart.Trip,
      trips: cart.Trips,
      startDate: cart.StartDate,
    };
  };

  @action
  startBooking = (value) => {
    if (value) {
      this.bookingInfo = {
        ...this.bookingInfo,
        bookingId: value.bookId,
        errorMessages: value.errorMessages,
      };
    } else {
      const err = LABELS.NODATA;
      this.bookingInfo = {
        ...this.bookingInfo,
        errors: [
          ...this.bookingInfo.errors,
          { Message: err },
        ],
      };
    }
  };

  @action
  continueBooking = (value) => {
    this.bookingInfo = { ...this.bookingInfo, errorMessages: value.errorMessages };
  };

  @action
  updateError = (errors) => {
    this.bookingInfo = {
      ...this.bookingInfo,
      errors: [
        ...this.bookingInfo.errors,
        ...errors,
      ],
    };
  };

  @action
  reset = () => {
    this.bookingInfo = { ...createNewState };
  };

  @action
  loadAnalogRooms = (value) => {
    const { cartItemId, analogRooms } = value;

    const cartItemByAnalogRooms = {
      ...this.bookingInfo.cartItemByAnalogRooms,
      [cartItemId]: [...preparedAnalogRooms(analogRooms)],
    };

    this.bookingInfo = {
      ...this.bookingInfo,
      cartItemByAnalogRooms,
    };
  };

  @action
  updateAnalogRooms = (analogRooms) => {
    const { roomId, cartItemId, value } = analogRooms;
    const { cartItemByAnalogRooms } = this.bookingInfo;

    let mappedAnalogRooms = cartItemByAnalogRooms[cartItemId];

    Object.keys(cartItemByAnalogRooms).forEach((id) => {
      if (Number(id) === cartItemId) {
        mappedAnalogRooms = cartItemByAnalogRooms[id].map((item) => ({
          ...item,
          checked: item.Guid === roomId ? value : !value,
        }));
      }
    });

    this.bookingInfo = {
      ...this.bookingInfo,
      cartItemByAnalogRooms: {
        ...this.bookingInfo.cartItemByAnalogRooms,
        [cartItemId]: [...mappedAnalogRooms],
      },
    };
  };
}

const BookingStore = new Store();

export { BookingStore, Store as BookingStoreType };
