type MOBX_STORES_TYPES =
  'airlineSeatsStore' |
  'airlineBaggageStore' |
  'aeroexpressStore' |
  'approvalSchemesStore' |
  'approvalERSchemesStore' |
  'settingSchemeStore' |
  'travelPolicyStore' |
  'travelPolicyListStore' |
  'departmentsStore' |
  'sidePanelStore' |
  'cloudPaymentsStore' |
  'bonusProgramStore' |
  'tripsStore' |
  'tripCommentsStore' |
  'smartdeskStore' |
  'uiSettingsStore' |
  'trainSearchStore' |
  'trainTicketsStore' |
  'trainStore' |
  'trainSavedTicketStore' |
  'trainSavedTicketsStore' |
  'settingsStore' |
  'travelApprovalStore' |
  'travelApprovalsStore' |
  'approvesStore' |
  'taxiStore' |
  'newHotelsStore' |
  'chartsAnalyticsStore' |
  'chartsAnalyticsGeneralStore' |
  'chartsAnalyticsSmartSummaryStore' |
  'chartsAnalyticsSummaryStore' |
  'requestDialogStore' |
  'contractHotelStore' |
  'bannerStore' |
  'expenseReportsStore' |
  'transferSearchStore' |
  'transferStore' |
  'expenseReportStore' |
  'approveStore' |
  'breadCrumbsStore' |
  'popupsStore' |
  'approveReportStore' |
  'vipHallStore' |
  'vipHallSearchStore' |
  'simpleSignatureStore' |
  'paymentMethodStore' |
  'filtersStore' |
  'qualityStore' |
  'eventStore' |
  'favoritesStore' |
  'tripTagsStore' |
  'insuranceStore' |
  'employeePaymentStore' |
  'bookingStore';

interface I_MOBX_STORES {
  [n: string]: MOBX_STORES_TYPES
}

const MOBX_STORES: I_MOBX_STORES = {
  AEROEXPRESS: 'aeroexpressStore',
  APPROVAL_SCHEMES: 'approvalSchemesStore',
  APPROVAL_ER_SCHEMES: 'approvalERSchemesStore',
  AIRLINE_SEATS: 'airlineSeatsStore',
  AIRLINE_BAGGAGE: 'airlineBaggageStore',
  SETTING_SCHEME: 'settingSchemeStore',
  TRAVEL_POLICY: 'travelPolicyStore',
  TRAVEL_POLICY_LIST: 'travelPolicyListStore',
  DEPARTMENTS: 'departmentsStore',
  SIDE_PANEL: 'sidePanelStore',
  CLOUD_PAYMENTS: 'cloudPaymentsStore',
  BONUS_PROGRAM: 'bonusProgramStore',
  TRIPS: 'tripsStore',
  TRIP_COMMENTS: 'tripCommentsStore',
  SMARTDESK: 'smartdeskStore',
  UI_SETTINGS: 'uiSettingsStore',
  TRAIN_SEARCH: 'trainSearchStore',
  TRAIN_TICKETS: 'trainTicketsStore',
  TRAIN: 'trainStore',
  TRAIN_SAVED_TICKET: 'trainSavedTicketStore',
  TRAIN_SAVED_TICKETS: 'trainSavedTicketsStore',
  SETTINGS_STORE: 'settingsStore',
  TRAVEL_APPROVAL_STORE: 'travelApprovalStore',
  TRAVEL_APPROVALS: 'travelApprovalsStore',
  APPROVES: 'approvesStore',
  TAXI_STORE: 'taxiStore',
  NEW_HOTELS_STORE: 'newHotelsStore',
  VIP_STORE: 'vipHallStore',
  VIP_SEARCH_STORE: 'vipHallSearchStore',
  CHARTS_ANALYTICS_STORE: 'chartsAnalyticsStore',
  CHARTS_ANALYTICS_GENERAL_STORE: 'chartsAnalyticsGeneralStore',
  CHARTS_ANALYTICS_SMART_SUMMARY_STORE: 'chartsAnalyticsSmartSummaryStore',
  CHARTS_ANALYTICS_SUMMARY_STORE: 'chartsAnalyticsSummaryStore',
  REQUEST_DIALOG_STORE: 'requestDialogStore',
  CONTRACT_HOTEL_STORE: 'contractHotelStore',
  BANNER_STORE: 'bannerStore',
  POPUPS_STORE: 'popupsStore',
  PAYMENT_METHOD: 'paymentMethodStore',
  EXPENSE_REPORTS_STORE: 'expenseReportsStore',
  TRANSFER_SEARCH_STORE: 'transferSearchStore',
  TRANSFER_STORE: 'transferStore',
  EXPENSE_REPORT_STORE: 'expenseReportStore',
  EXPENSE_REPORTS_STORE_APPROVE: 'approveStore',
  BREAD_CRUMBS_STORE: 'breadCrumbsStore',
  APPROVE_REPORT_STORE: 'approveReportStore',
  SIMPLE_SIGNATURE_STORE: 'simpleSignatureStore',
  FILTERS_STORE: 'filtersStore',
  QUALITY_STORE: 'qualityStore',
  EVENT_STORE: 'eventStore',
  FAVORITES_STORE: 'favoritesStore',
  TRIP_TAGS_STORE: 'tripTagsStore',
  EMPLOYEE_PAYMENT: 'employeePaymentStore',
  INSURANCE_STORE: 'insuranceStore',
  BOOKING: 'bookingStore',
};

export { MOBX_STORES };
export type { MOBX_STORES_TYPES, I_MOBX_STORES };
