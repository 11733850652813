import { SERVICETYCASE, SERVICETYPE } from '../constants/serviceType';
import { IRoute, IService } from '../types/booking';

const correctServiceName = (service: string, msg: string) => (type: string) => `${SERVICETYCASE[service](type)} ${msg}`;

const getFullServiceName = (service: IService) => {
  const fullName = service.Employees.map(employee => `${employee.Employee.Surname} ${employee.Employee.Name}`);
  let serviceName = '';

  switch (service.ServiceType) {
    case SERVICETYPE.AIR: {
      const data = JSON.parse(service.Data);
      const places = data.Routes
        .map((route: IRoute) => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
      serviceName = `${places.join('; ')}`;
      break;
    }
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.hotel.Name}`;
      break;
    }
    case SERVICETYPE.TRAIN: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StationDepart} ${data.StationArrive}`;
      break;
    }
    case SERVICETYPE.TRANSFER: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StartPlace.Name} ${data.EndPlace.Name}`;
      break;
    }
  }

  return correctServiceName(service.ServiceType, `${serviceName} ${fullName.join(' ')}`);
};

export { correctServiceName, getFullServiceName };
